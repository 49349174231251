.imageContainer {
  @apply flex mt-16 z-0;
  max-width: 448px;
  max-height: 360px;

  @screen xl {
    max-width: 672px;
    max-height: none;
    margin-bottom: none;
  }

  @media (max-height: 768px) {
    max-width: 448px;
    max-height: 360px;
  }
}

.formContainer {
  @apply md:w-max mx-auto lg:mx-0 w-full md:max-w-[384px] xl:max-w-[608px] flex flex-col bg-display-0 md:px-[40px] xl:px-[112px] md:rounded-tr-6xl;
  @apply justify-center items-center min-h-screen;
}
